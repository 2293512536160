<template>
    <zw-sidebar @shown="shown" :title="$t('mail.title.mail')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div v-if="!loading">
                <b-row v-if="!payload.selectedData">
                    <b-col cols="6">
                        <b-form-group :label="$t(labelPrefix+'tags')">
                            <multiselect v-model="form.tags"
                                         name="tags"
                                         :searchable="true"
                                         :multiple="true"
                                         :options="Object.keys(getCustomerTags())"
                                         select-label=""
                                         :custom-label="opt => getCustomerTags()[opt]"
                                         size="sm"
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                    <b-col cols="6">
                        <b-form-group :label="$t(labelPrefix+'groups')">
                            <multiselect v-model="form.groups"
                                         name="groups"
                                         :searchable="true"
                                         :multiple="true"
                                         :options="Object.keys(groupOptions)"
                                         :custom-label="opt => getCustomerTags()[opt]"
                                         select-label=""
                                         size="sm"
                            ></multiselect>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="6">
                        <zw-input-group v-model="form.cc"
                                        name="cc"
                                        validate="email"
                                        :label-prefix="labelPrefix"
                                        size="sm"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="6">
                        <zw-input-group v-model="form.bcc"
                                        name="bcc"
                                        :label-prefix="labelPrefix"
                                        size="sm"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-select-group v-model="form.template"
                                         :options="getEmailTemplates()"
                                         name="template"
                                         :label-prefix="labelPrefix"
                                         text-field="name"
                                         value-field="id"
                                         @change="templateSelected"
                                         size="sm"
                        ></zw-select-group>
                    </b-col>

                    <b-col cols="12">
                        <zw-input-group v-model="form.subject"
                                        name="subject"
                                        :label-prefix="labelPrefix"
                                        validate="required"
                                        size="sm"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <label>{{ $t(labelPrefix + 'body') }}</label>
                        <zw-ckeditor v-model="form.body"></zw-ckeditor>
                    </b-col>
                    <b-col cols="12">
                        <zw-attachments v-model="form.attachments"
                                        :offering-id="form.offering_id"
                                        :customer-id="form.customer_id"
                        />
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            <font-awesome-icon class="mr-2" icon="envelope"/>
                            {{ $t('common.button.send_mail') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'
import ZwModelSelectorGroup from "@/components/ZwModelSelectorGroup";
import ZwAttachments from "@/components/ZwAttachments";

export default {
    name: 'EmailsModal',
    components: {ZwAttachments, ZwModelSelectorGroup},
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            selected_file: null,
            defaultForm: {
                tags: null,
                groups: null,
                cc: '',
                bcc: '',
                template: '',
                subject: '',
                body: '',
                media: [],
            },
            form: {},
            labelPrefix: 'mail.label.',
            templates: null,
            fileIcons: {
                'pdf': 'file-pdf',
                'docx': 'file-word',
                'doc': 'file-word',
                'csv': 'file-csv',
                'png': 'file-image',
                'jpg': 'file-image',
                'gif': 'file-image',
                'jpeg': 'file-image',
                'webp': 'file-image',
                'txt': 'file-lines',
            }
        }
    },
    methods: {
        ...mapGetters('Mail', ['getDocumentMailData']),
        ...mapGetters('CommonData', ['getCustomerTags', 'getGroups']),
        shown() {
            this.$store.dispatch('Mail/fetchDocumentMailData', {
                id: this.payload.documentId,
                type: this.payload.docType
            })
                .then(() => {
                    let documentData = this.getDocumentMailData();
                    this.form = {
                        ...JSON.parse(JSON.stringify(this.defaultForm)),
                        ...JSON.parse(JSON.stringify(documentData))
                    }
                    this.templates = documentData.templates

                    if (typeof this.form.attachments == 'undefined') {
                        this.$set(this.form, 'attachments', [])
                    }
                    this.$refs['observer'].reset()
                })
                .finally(() => {
                    this.loading = false
                })
        },
        templateSelected() {
            const template = this.getEmailTemplates().find(template => template.id == this.form.template)

            if (template) {
                this.$set(this.form, 'subject', template.subject)
                this.$set(this.form, 'body', template.body)
            }
        },
        getEmailTemplates() {
            return this.templates
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true

                    let sendData = {
                        form: this.form,
                        ...this.payload.selectedData
                    }

                    this.$store.dispatch('Mail/sendEmails', sendData)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    },
    computed: {
        groupOptions: function () {
            let options = {}
            this.getGroups().forEach(group => {
                options[group.id] = group.name
            })

            return options
        },
    }
}
</script>

<style>
.image-preview {
    margin-top: 20px;
}

.image-preview .background {
    position: relative;
}

.image-preview .background .remove {
    opacity: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    height: 30px;
    text-align: center;
}

.image-preview .background .edit, .image-preview .background .save {
    opacity: 0;
    position: absolute;
    top: 50px;
    right: 10px;
    height: 30px;
    text-align: center;
}

.image-preview .background .edit button {
    padding: 0.375rem 0.7rem;
}

.image-preview .background:hover .remove, .image-preview .background:hover .edit, .image-preview .background:hover .save {
    opacity: 1;
}

.image-preview .background {
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain;
    height: 200px;
    border-radius: 5px;
    background-color: white;
}

.download .image-preview .background .file-type {
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 1;
}

.image-preview .background a {
    color: gray;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: white;
    padding: 3px;
    border-radius: 3px;
    opacity: 1;
}

.editblock {
    color: gray;
    position: absolute;
    bottom: 10px;
    left: 10px;
    background-color: white;
}

.download .image-preview .background {
    height: 100px;

    background-color: white;
}

#customdropzone {
    background-color: lightcyan;
    border-radius: 5px;
    margin-top: 20px;
    padding: 70px 20px;
}

.download #customdropzone {
    height: 100px;
    padding: 25px;
}

.input_media {
    display: block;
    width: 100%;
}
</style>